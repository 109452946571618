.redeem-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);

	background: rgba(white, 0.25);
	backdrop-filter: blur(vw(20));
	z-index: 100;

	display: flex;
	flex-direction: column;
	gap: vw(24);
	padding: vw(60) 0 vw(60);

	overflow-x: hidden;
	overflow-y: auto;

	.panel {
		position: relative;
		padding: vw(20);
		width: vw(320);
		border-radius: vw(24);
		border: vw(1) solid white;
		background: linear-gradient(124.01deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.3) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.7) 72.44%);
		backdrop-filter: blur(5px);
		z-index: 1;
		margin: auto;
		text-align: center;

		.title {
			font-family: var(--font-family-title);
			font-size: vw(30);
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			margin-bottom: vw(22);
		}

		.subtitle,
		.code,
		.text {
			font-family: var(--font-family-title);
			font-size: vw(14);
			font-style: normal;
			font-weight: 800;
			line-height: normal;
		}

		.code {
			margin-bottom: 1em;
		}

		.code,
		.text {
			font-weight: 300;
		}

		.text {
			margin-bottom: vw(22);
		}

		.button {
			& + .button {
				margin-top: vw(16);
			}
		}
	}
}