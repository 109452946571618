.bubble {
	z-index: 0;
	--size: #{vw(224)};
	width: var(--size);
	height: var(--size);
	overflow: hidden;

	display: grid;

	video {
		grid-row: 1;
		grid-column: 1;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&:after {
		content: '';
		position: absolute;
		top: 8.5%;
		left: 10.7%;
		width: 80%;
		height: 80%;
		border-radius: 100%;
		z-index: -1;
	}

	&.treasurehunt:after {
		background: linear-gradient(63deg, rgba(236, 255, 221, 0.40) 37.44%, rgba(255, 255, 255, 0.04) 63.56%);
		backdrop-filter: blur(vw(7.5));
	}

	&.white:after {
		background: linear-gradient(63deg, rgba(210, 202, 254, 0.50) 20.03%, rgba(218, 218, 218, 0.20) 63.56%);
		backdrop-filter: blur(vw(5));
	}

	&.blue:after {
		background: radial-gradient(50% 50% at 50% 50%, rgba(51, 77, 211, 0.50) 24.5%, rgba(223, 223, 223, 0.50) 82.5%);
		backdrop-filter: blur(vw(7.5));
	}
	
	&.neutral:after {
		background: linear-gradient(63deg, rgba(191, 255, 247, 0.50) 37.44%, rgba(255, 255, 255, 0.30) 63.56%);
		backdrop-filter: blur(vw(5));
	}

	&.red:after {
		background: linear-gradient(63deg, rgba(255, 191, 191, 0.50) 37.44%, rgba(255, 255, 255, 0.30) 63.56%);
		backdrop-filter: blur(vw(5));
	}

	&.green:after {
		background: linear-gradient(63deg, rgba(191, 255, 209, 0.50) 37.44%, rgba(255, 255, 255, 0.30) 63.56%);
		backdrop-filter: blur(vw(5));
	}
}