.popup-poll {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 999999;
	overflow: hidden;
	background: rgba(white, 0.1);
	background: linear-gradient(344.62deg, rgba(239, 135, 140, 0.3) -1.81%, rgba(241, 172, 133, 0.3) 17.16%, rgba(237, 203, 137, 0.3) 41.8%, rgba(179, 225, 228, 0.3) 64.09%);
	backdrop-filter: blur(20px);
	padding: vw(20) vw(45);

	display: flex;

	.back-icon {
		position: absolute;
		top: vw(28);
		left: vw(45);
	}

	.single-poll {
		margin: auto;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 100%;

		&::-webkit-scrollbar-button:start:increment {
			width: vw(20);
			display: block;
		}

		&::-webkit-scrollbar-button:end:increment {
			width: vw(20);
			display: block;
		}

		.top {
			flex-direction: column;
			padding-right: vw(50);

			.text {
				margin-bottom: vw(5);
			}

			.reward {
				font-weight: 400;
			}

			.close-icon {
				position: absolute;
				top: 50%;
				right: vw(20);
				transform: translate(0, -50%);
			}
		}
	}

	&.appear,
	&.enter {
		opacity: 0;

		&-active {
			opacity: 1;
			transition: opacity 0.3s $easeOutCubic;
		}
	}
}