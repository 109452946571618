html {
	font-size: 100%;
	background-size: 100% auto;
	background-color: var(--color-background-secondary);
}

body {
	position: relative;
	-webkit-overflow-scrolling: touch;
	background-color: var(--color-background-secondary);
	background: url($CDN_BASE + 'app-bg.jpg') no-repeat center center fixed;
	background-size: cover;
	z-index: 1;
}

html,
body {
	padding: 0;
	margin: 0;
	overflow: hidden;
	height: calc(var(--vh, 1vh) * 100);
}

.main-content {
	position: relative;
	z-index: 1;
}

img {
	max-width: 100%;
	height: auto;
}

*, ::before, ::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
	text-decoration: none;
}

button {
	border: none;
}

.container, .container-fluid {
	width: calc(100% - var(--container-gutter));
}

.section-block {
	margin-top: var(--section-spacing-top);
	margin-bottom: var(--section-spacing-bottom);

	&.with-background {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: var(--section-spacing-top);
		padding-bottom: var(--section-spacing-bottom);
	}
}



*::-webkit-scrollbar {
	width: vw(3);
	height: vw(3);
}

*::-webkit-scrollbar-track {
	background: rgba(white, 0.2);
	border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--color-accent-primary);
	border-radius: 4px;
}

.skip-tutorial {
	position: fixed;
	top: vw(10);
	left: vw(10);
	z-index: 999;
	opacity: 0.5;
	font-family: var(--font-family-title);
	font-weight: bold;
}

.page.enter,
.page.appear {
	*::-webkit-scrollbar,
	*::-webkit-scrollbar-track,
	*::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
}

pre {
	white-space: pre-wrap;
}

.splash {
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	overflow: hidden;

	.logo {
		position: relative;
		width: auto;
		height: vw(111);

		img {
			width: auto;
			height: 100%;
			max-width: none;
		}
	}

	.loading {
		margin-top: vw(20);
		text-align: center;
		font-size: vw(16);
	}

	.small {
		position: fixed;
		bottom: vw(10);
		left: 0;
		width: 100%;
		text-align: center;
		font-size: vw(8);
	}

	&.appear,
	&.enter {
		.loading,
		.logo {
			opacity: 0;
			transform: translate(0, #{vw(20)});
		}

		&-active {
			.loading,
			.logo {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic 0.3s, transform 0.6s $easeOutCubic 0.3s;
			}

			.loading {
				transition-delay: 0.4s;
			}
		}
	}
}

#ot-sdk-btn,
#ot-sdk-cookie-policy {
	display: none;
}

body #ot-sdk-btn-floating.ot-floating-button {
	bottom: calc(var(--menu-height) + #{vw(10)});
	// display: none;
}

#onetrust-consent-sdk #onetrust-pc-sdk {
	margin-top: 0 !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
}