.menu {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: var(--menu-height);
	background: rgba(255, 255, 255, 0.40);
	backdrop-filter: blur(10px);
	z-index: 2;

	.nav {
		position: relative;
		z-index: 3;
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: vw(2) solid #FFF;
		opacity: 0.5;
		backdrop-filter: blur(5px);
		content: '';
		z-index: 1;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		background: url(#{$CDN_BASE}menu-bg.jpg) lightgray 50% / cover no-repeat;
		mix-blend-mode: overlay;
		backdrop-filter: blur(5px);
		z-index: 2;
	}

	.menu-item {
		font-size: vw(12);
		font-weight: 400;
		line-height: 1;

		&.active {
			// font-weight: 700;
			text-shadow: 0.5px 0 0 rgba(0,0,0,0.5),
							0px 0.5px 0 rgba(0,0,0,0.5),
							-0.5px 0 0 rgba(0,0,0,0.5),
							0px -0.5px 0 rgba(0,0,0,0.5);
		}
	}

	.center-btn {
		position: relative;

		background: rgba(0, 0, 0, 0.1);
		box-shadow: 0px vw(4) vw(8.8) rgba(0, 0, 0, 0.4);
		border-radius: 100%;
		backdrop-filter: blur(#{vw(10)});

		.menu-icon {
			position: relative;
			z-index: 1;
			width: vw(90);
			height: vw(90);
		}

		.cap {
			position: absolute;
			top: calc(50% + #{vw(-2)});
			left: calc(50% + #{vw(2)});
			transform: translate(-50%, -50%);
			width: vw(69);
			height: vw(69);
			z-index: 2;
			transition: transform 0.3s;
		}

		.shadow {
			position: absolute;
			width: vw(41);
			height: vw(12);
			top: vw(56);
			left: calc(50% - #{vw(19)});
			border-radius: 100%;

			background: rgba(0, 0, 0, 0.8);
			filter: blur(#{vw(4)});
			opacity: 0;
			transition: opacity 0.3s;
		}

		&.active {
			.cap {
				transform: translate(-50%, calc(-50% - #{vw(6)}));
			}

			.shadow {
				opacity: 1;
			}
		}
	}
}