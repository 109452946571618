.rewards-page {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100 - var(--menu-height));
	padding-bottom: vw(20);
	padding-top: calc(var(--header-clear));

	justify-content: flex-start;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;

	.rewards-list {
		width: calc(100% - vw(80));
		height: vw(330);
		margin-top: auto;

		.swiper {
			overflow: visible;
			width: vw(330);
			height: 100%;
		}
	}

	.reward {
		.bubble-container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: vw(-396);
			left: vw(-156);
			width: vw(552);
			height: vw(552);

			transform: scale(0.55) translate(0, -10%);
			transform-origin: bottom center;

			transition: transform 0.3s ease-in-out;
		}

		img {
			position: relative;
			z-index: 1;
			width: vw(350);
			height: auto;
			transform: translate(0, -10%);
		}

		.bubble {
			position: absolute;
			top: 0;
			left: 0;
			--size: #{vw(552)};
		}

		&.swiper-slide-active {
			.bubble-container {
				transform: scale(1) translate(0, 0);
			}
		}
	}


	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		z-index: 1;
		width: vw(330);

		.coupon-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
			z-index: -1;
			backdrop-filter: blur(vw(10));
		}

		&::-webkit-scrollbar-button:start:increment {
			width: vw(20);
			display: block;
		}

		&::-webkit-scrollbar-button:end:increment {
			width: vw(20);
			display: block;
		}

		pre {
			overflow: visible;
			flex: 0 0 auto;
		}

		.top {
			position: relative;
			height: vw(103);
			padding: vw(20);
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: vw(17);
				width: calc(100% - #{vw(34)});

				border-bottom: vw(2) dotted #FF2626;
			}

			.type {
				font-weight: 600;
				font-size: vw(14);
				line-height: vw(17);
				/* identical to box height */
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				align-self: flex-start;
				border-radius: vw(20);
				height: vw(20);
				padding: vw(1) vw(10) vw(0);
				color: white;
				margin-bottom: vw(11);
				flex: 0 0 auto;
				
				background-color: #FF2626;
			}

			.title {
				font-size: vw(22);
				font-weight: 700;
			}
		}

		.bottom {
			padding: vw(20);
			height: 100%;
			display: flex;
			flex-direction: column;

			.details {
				font-size: vw(12);
				font-weight: 400;

				strong {
					font-weight: 700;
				}
			}

			.subtitle {
				text-align: center;
				font-size: vw(16);
				font-weight: 700;
				margin: auto vw(30);
			}

			.button {
				margin-top: auto;
			}
		}
	}

	.reward.empty {
		.top {
			justify-content: center;
			
			.type {
				display: none;
			}
		}

		.bottom {
			display: flex;
			align-items: center;
		}
	}
}