label.select {
	position: relative;

	font-family: var(--font-family-title);
	font-style: normal;
	font-weight: bold;
	font-size: vw(16);
	line-height: 116%;
	/* or 23px */
	letter-spacing: -0.022em;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	height: vw(60);
	border-radius: vw(20);
	background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
	backdrop-filter: blur(vw(25));
	border: vw(2) solid white;

	color: var(--color-text-primary);

	.text {
		padding: 0 vw(20);
		// border-right: vw(1) solid rgba(#000, 0.3);
		flex: 1 1 100%;
	}

	.arrow {
		padding: 0 vw(13) 0 vw(30);
		flex: 0 0 auto;
	}

	.chevron-down {
		width: vw(24);
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}