$CDN_BASE: 'https://tccccgppocappdata.blob.core.windows.net/assets/lkwd-poc/';

@at-root {
	:root {
		--baseline: 393;
		//General variables
		--color-text-primary: #484848;
		--color-text-secondary: #fff;
		--color-background-primary: #573292;
		--color-background-secondary: #ffffff;
		--color-background-primary-dark: #2B0864;
		--color-accent-primary: #009787;
		--color-accent-secondary: #7ABBCD;
		--font-family-title: 'TCCC-UnityHeadline', sans-serif;
		--font-family-body: 'TCCC-UnityText', sans-serif;
		--font-family-button: 'Inter', sans-serif;

		--color-overlay-background: #{rgba(#009787, 0.9)};

		--color-treasure-hunt: #0FB9A4;
		--color-product-scan: #B041E5;
	
		--color-button-text: #fff;
		--color-button-border: black;
		--color-button-background: black;

		--menu-height: #{vw(60)};
		--header-clear: #{vw(85)};
		
		--glass-transparent: rgba(255, 255, 255, 0.81);
		--glass-transparent-65: rgba(255, 255, 255, 0.65);
		--glass-gradient: linear-gradient(124deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.30) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.70) 72.44%);
		--rainbow-gradient-faded: linear-gradient(90deg, rgba(253, 0, 0, 0.4) 0%, rgba(247, 153, 3, 0.4) 41%, rgba(113, 255, 204, 0.4) 98%);
		--rainbow-gradient: linear-gradient(90deg, #FD0000 0%, #F79903 41%, #71FFCC 98%);

		border-radius: 10px;

		@media (display-mode: fullscreen) {
			--menu-height: #{vw(98)};
		}
	
		--container-size: 100%;
		--container-gutter: 0px;
	}
}

%inverted_colors {
	--color-text-primary: #333;
	--color-background-primary: #ffffff;
}
