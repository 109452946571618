.main-header {
	position: absolute;
	top: vw(17);
	left: 50%;
	width: vw(360);
	height: vw(56);
	z-index: 50;
	border-radius: vw(8);
	z-index: 100;
	transform: translate(-50%, 0);

	display: grid;
	grid-template-columns: vw(290) 1fr;

	.header-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.left {
		display: flex;
		flex: 1 1 100%;
		align-items: center;
		padding: 0 vw(8);
		height: 100%;
		
		.avatar {
			width: vw(50);
			height: vw(50);
			margin-right: vw(5);
			flex: 0 0 auto;

			img {
				width: 100%;
				height: 100%;
				transform: scale(1.2);
			}
		}
	}
		
	.text {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.greetings {
			font-size: vw(12);
			line-height: 1.2;

			.name {
				font-size: vw(14);
			}
		}

		.xp {
			font-weight: 400;
			font-size: vw(12);
			line-height: vw(14);
			text-align: center;
			color: #484848;

			.amount {
				font-weight: 700;
				font-size: vw(14);
				line-height: vw(17);
				text-align: center;
			}
		}
	}

	.right {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;

		.notification {
			position: relative;

			.bell-icon {
				width: vw(22);
				height: auto;
			}

			.badge {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: vw(-3);
				right: vw(-5);
				padding: 0 vw(3);
				min-width: vw(15);
				height: vw(15);
				background-color: #EA4242;
				border: vw(2) solid white;
				border-radius: vw(8);
				font-size: vw(7);
				color: white;
				font-weight: 700;
				line-height: 0.8;
			}
		}
	}

	.single-notification {
		position: absolute;
		top: calc(100% + #{vw(6)});
		left: 1%;
		width: 98%;

		padding: vw(9) vw(40) vw(10) vw(10);
		border-radius: vw(5);
		background: rgba(0, 0, 0, 0.30);
		backdrop-filter: blur(vw(5));

		color: white;
		font-size: vw(16);
		font-weight: 400;
		line-height: 1.1;

		.close-icon {
			position: absolute;
			top: 50%;
			right: vw(5);
			transform: translate(-50%, -50%);
		}

		&:after {
			position: absolute;
			bottom: 100%;
			right: vw(24);
			content: '';
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 vw(8) vw(4) vw(8);
			border-color: transparent transparent rgba(0, 0, 0, 0.30) transparent;
			transform: rotate(0deg);
		}

		&.appear,
		&.enter {
			transform-origin: 87% 0%;
			transform: scale(0);
			opacity: 0;

			&-active {
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.45s $easeOutCubic var(--delay, 0.2s), transform 0.45s $easeOutCubic var(--delay, 0.2s);
			}
		}
	}
}