.realtime-visualizer {
	font-size: var(--visualizer-font-size, 12px);
	position: fixed;
	top: 0;
	left: 0;
	width: 320px;
	color: var(--visualizer-text-color, #787878);
	background: var(--visualizer-background-color, #fff);
	margin: 0;
	box-shadow:
		0px 0px 2.7px rgba(0, 0, 0, 0.022),
		0px 0px 6.9px rgba(0, 0, 0, 0.031),
		0px 0px 14.2px rgba(0, 0, 0, 0.039),
		0px 0px 29.2px rgba(0, 0, 0, 0.048),
		0px 0px 80px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	padding: 1em 1.5em;
	z-index: 9999999;

	--visualizer-max-height: 300px;
	--visualizer-time-width: 23%;
	--visualizer-dot-size: 0.5em;
	--visualizer-dot-spacing: 1em;

	&:before {
		content: 'R';
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 10px;
		left: 100%;
		width: 20px;
		height: 20px;
		background: pink;
	}

	transform: translate(-100%, 0);
	transition: transform 0.3s ease-in-out;
	
	&.visible {
		transform: translate(0, 0);
	}

	.top {
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.6em;
		margin-bottom: 1em;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		.title {
			line-height: 1;
			font-size: 1.5em;
			font-weight: 300;
			opacity: 0.6;
		}
	}

	.messages {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
		max-height: var(--visualizer-max-height);

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: rgba(#000, 0.025);
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(#000, 0.05);
			border-radius: 0;
		}
	}

	.event {
		position: relative;
		display: flex;
		padding-bottom: 0.8em;
		
		&:last-child {
			padding-bottom: 0em;
			
			&::before {
				height: 100%;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: var(--visualizer-dot-size);
			left: calc(var(--visualizer-time-width) - var(--visualizer-dot-spacing) + (var(--visualizer-dot-size) / 2));
			width: 1px;
			height: calc(100% + 0.8em);
			background: rgba(0,0,0,0.2);
			transform: translate(-50%, 0);
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 0.5em;
			left: calc(var(--visualizer-time-width) - var(--visualizer-dot-spacing));
			width: var(--visualizer-dot-size);
			height: var(--visualizer-dot-size);
			border-radius: 100%;
			background-color: var(--visualizer-accent-color, #fcba00);
		}

		.time {
			width: var(--visualizer-time-width);
			align-self: stretch;
			font-size: 0.8em;
			position: relative;
			top: 0.3em;
			text-transform: lowercase;
			line-height: var(--visualizer-font-size);
		}

		.content {
			padding-left: 0.3em;
			flex: 0 0 auto;
			width: calc(100% - var(--visualizer-time-width));
		}

		.name {
			font-size: 1em;
			font-weight: 600;
			line-height: var(--visualizer-font-size);
			flex: 0 0 auto;
			margin-bottom: 0.2em;
			word-break: break-all;
		}

		.additional-infos {
			font-size: 0.8em;
			display: flex;

			.item-placeholder {
				width: (4em / 3);
				height: (4em / 3);
				font-size: 3em;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			img {
				width: 4em;
				height: 4em;
				border-radius: 1em;
				object-fit: cover;
			}
		}
	}

	.no-events {
		text-align: center;
	}
}