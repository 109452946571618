.home {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc((var(--vh, 1vh) * 100) - var(--menu-height));

	&:has(.tutorial-bg) {
		overflow: visible;
	}

	.element {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 82%;
			height: 82%;
			transform: translate(-50%, -50%);
			border-radius: 100%;
			pointer-events: all;
		}

		.bubble {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			pointer-events: none;
		}

		&.isolate {
			z-index: 999;

			&, &:after {
				pointer-events: none;
			}
		}
	}

	.tutorial-bg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 300vmax;
		height: 300vmax;
		background: rgba(white, 0.1);
		background: linear-gradient(344.62deg, rgba(239, 135, 140, 0.3) -1.81%, rgba(241, 172, 133, 0.3) 17.16%, rgba(237, 203, 137, 0.3) 41.8%, rgba(179, 225, 228, 0.3) 64.09%);
		backdrop-filter: blur(20px);
		transform: translate(-50%, -50%);
		z-index: 500;

		&.appear,
		&.enter {
			opacity: 0;

			&-active {
				opacity: 1;
				transition: opacity 0.6s $easeOutCubic;
			}
		}

		&.exit {
			opacity: 1;
			transition: opacity 0.3s $easeInCubic;

			&-active {
				opacity: 0;
			}
		}
	}

	&.tutorial {
		.product-scan,
		.polls,
		.treasure-hunt {
			opacity: 0;
			transition: opacity 0.2s $easeInCubic;

			&.isolate {
				opacity: 1;
				transition: opacity 0.6s $easeOutCubic;
			}
		}
	}

	.treasure-hunt {
		top: vw(28);
		left: vw(-67);
		width: #{vw(397)};
		height: #{vw(397)};
		z-index: 3;

		img {
			position: absolute;
			top: vw(47);
			left: vw(94);
			width: vw(179);
			height: vw(179);
		}

		.animated-bottle-cap {
			--size: #{vw(159)};
			position: absolute;
			top: vw(39);
			left: vw(238);
			z-index: 2;
		}

		.title {
			position: absolute;
			top: vw(225);
			left: vw(107);
			margin-top: auto;
			font-weight: 700;
			font-size: vw(40);
			line-height: 0.8;
			z-index: 1;

			span {
				font-size: vw(48);
			}
		}

		.description {
			font-weight: 400;
			font-size: vw(18);
			line-height: 110%;
		}
	}

	.product-scan {
		top: vw(300);
		left: vw(152);
		width: vw(257);
		height: vw(257);
		z-index: 2;
		padding: 0 vw(41) vw(93) vw(83);

		.rotate {
			position: absolute;
			top: 50%;
			left: vw(-35);
			width: vw(190);
			height: vw(190);
			transform: translateY(-50%) rotate(-2deg);

			img {
				width: 100%;
				height: 100%;
			}
		}

		.title {
			margin-top: auto;
			font-weight: 700;
			font-size: vw(30);
			line-height: 100%;
			margin-bottom: vw(10);

			span {
				font-size: vw(46);
			}
		}
	}

	.polls {
		top: vw(383);
		left: vw(-3);
		width: vw(225);
		height: vw(225);
		z-index: 1;
		padding: 0 vw(55) vw(68) vw(61);

		img {
			position: absolute;
			top: vw(8);
			left: vw(41);
			width: vw(141);
			height: vw(141);
		}

		.title {
			margin-top: auto;
			font-weight: 700;
			font-size: vw(40);
			line-height: 100%;
		}
	}

	.step-1 {
		top: vw(300);
		left: vw(99);
		width: #{vw(299)};
		height: #{vw(299)};
		padding: 0 vw(48) vw(15);
	}

	.step-2 {
		top: vw(117);
		left: vw(54);
		width: #{vw(266)};
		height: #{vw(266)};
		padding: 0 vw(30) vw(20);
	}

	.step-3 {
		top: vw(278);
		left: vw(134);
		width: #{vw(239)};
		height: #{vw(239)};
		padding: 0 vw(30) vw(5);
	}

	.tutorial-step {
		z-index: 5003;
		text-align: center;
		font-weight: 700;
		font-size: vw(20);
		color: black;

		&.appear,
		&.enter {
			opacity: 0;
			transform: translate(0, #{vw(50)}) scale(0.7);

			&-active {
				opacity: 1;
				transform: translate(0, 0) scale(1);
				transition: transform 0.3s $easeOutCubic var(--delay, 0.2s), opacity 0.3s $easeOutCubic var(--delay, 0.2s);
			}
		}
	}

	.tutorial-panel-ctn {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5010;
		display: flex;
		align-items: center;
		justify-content: center;

		.panel {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: vw(16);
			width: vw(300);
			padding: vw(16);
	
			border-radius: vw(16);
			border: vw(3) solid var(--glass-transparent);
			background: var(--glass-gradient);
			backdrop-filter: blur(vw(10));
	
			.title {
				font-family: var(--font-family-title);
				font-size: vw(30);
				font-weight: 800;
				line-height: 1.1;
			}
	
			.text {
				font-family: var(--font-family-title);
				font-size: vw(14);
				font-weight: 300;
				line-height: vw(20);
			}
		}

		&.appear,
		&.enter {
			.panel {
				opacity: 0;
				transform: translate(0, #{vw(40)});
			}

			.panel > * {
				opacity: 0;
				transform: translate(0, #{vw(20)});
			}

			.panel {
				overflow: hidden;
			}

			&-active {
				.panel,
				.panel > * {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.panel {
					--delay: 0s;
				}

				.panel :nth-child(1) { --delay: 0.1s; }
				.panel :nth-child(2) { --delay: 0.2s; }
				.panel :nth-child(3) { --delay: 0.3s; }
			}
		}
	}


	.btns {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: vw(20) vw(25) vw(20);
		z-index: 5010;
	}

	.cta.start {
		margin-left: auto;
	}

	.skip {
		color: black;
		font-family: var(--font-family-title);
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: vw(18);
		font-style: normal;
		font-weight: 600;
		line-height: vw(20);
		text-decoration-line: underline;
	}

	&.appear,
	&.enter {
		overflow: hidden;

		.element {
			opacity: 0;
			transform: translate(0, #{vw(50)}) scale(0.7);
			transform-origin: 50% 75%;
		}

		.element img,
		.element .animated-bottle-cap,
		.element .title {
			opacity: 0;
			transform: translate(0, #{vw(20)});
		}

		&-active {
			.element {
				opacity: 1;
				transform: translate(0, 0) scale(1);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s), opacity 0.6s $easeOutCubic var(--delay, 0s);
			}

			.element img,
			.element .animated-bottle-cap,
			.element .title {
				opacity: 1;
				transform: translate(0, 0) scale(1);
				transition: transform 0.6s $easeOutCubic var(--sub-delay, 0s), opacity 0.6s $easeOutCubic var(--sub-delay, 0s);
			}
		}

		.treasure-hunt { --delay: 0.0s }
		.product-scan { --delay: 0.2s }
		.polls { --delay: 0.4s }

		.element img { --sub-delay: calc(var(--delay) + 0.1s) }
		.element .animated-bottle-cap { --sub-delay: calc(var(--delay) + 0.2s) }
		.element .title { --sub-delay: calc(var(--delay) + 0.2s) }
	}
}

.es {
	.home {
		.treasure-hunt {
			.title {
				font-size: vw(34);

				span {
					font-size: vw(38);
				}
			}
		}

		.product-scan {
			.title {
				font-size: vw(20);

				span {
					font-size: vw(24);
				}
			}
		}

		.polls {
			.title {
				font-size: vw(22);
			}
		}

		.tutorial-step {
			font-size: vw(16);

			&.step-2 {
				padding: 0 vw(50) vw(20);
			}
		}
	}
}

.fr,
.de {
	.home {
		.polls {
			.title {
				font-size: vw(24);
			}
		}

		.tutorial-step.step-2 {
			font-size: vw(18);
		}
	}
}