.avatar-selection {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	padding: vw(25);

	display: grid;
	grid-template-rows: auto 1fr auto;
	gap: vw(15);

	.top {
		position: relative;
		border-radius: vw(20) vw(20) vw(20) vw(0);
		padding: vw(23);
		background: var(--glass-gradient);
		backdrop-filter: blur(vw(10));
		text-align: center;

		.title {
			font-family: var(--font-family-title);
			font-size: vw(20);
			font-weight: 600;
			line-height: 1.1;
			margin-bottom: vw(5);
		}

		.subtext {
			font-family: var(--font-family-title);
			font-size: vw(20);
			font-weight: 300;
			line-height: 1.1;
		}

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			border-radius: vw(20) vw(20) vw(20) vw(0);
			padding: vw(4);
			background: var(--rainbow-gradient-faded);
			-webkit-mask: linear-gradient(#fff 0 0) content-box, 
						linear-gradient(#fff 0 0);
			mask: linear-gradient(#fff 0 0) content-box, 
				linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
					mask-composite: exclude; 
		}
	}

	.panel {
		border-radius: vw(17);
		border: vw(2) solid white;
		background: var(--glass-gradient);
		width: 100%;
		padding: 0 vw(16);
		text-align: center;
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;

		&::-webkit-scrollbar-button:start:increment {
			width: vw(20);
			display: block;
		}

		&::-webkit-scrollbar-button:end:increment {
			width: vw(20);
			display: block;
		}
	}
	
	.avatar-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding: vw(16) 0;
	}

	.avatar {
		position: relative;
		cursor: pointer;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		.selection {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		svg {
			z-index: 1;
			width: vw(30);
			height: vw(30);
		}

		.locked {
			position: absolute;
			top: vw(-2);
			right: vw(-2);
			border-radius: 100%;
			background: black;
			color: white;
			width: vw(32);
			height: vw(32);
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				position: relative;
				top: vw(-1);
				width: vw(10);
				height: vw(16);
			}
		}

		&.disabled {
			pointer-events: none;

			.icon {
				opacity: 0.15;
			}
		}
	}

	.btn-ctn {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;

		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}

	&.appear,
	&.enter {
		.panel,
		.top,
		.avatar {
			opacity: 0;
			transform: translate(0, #{vw(40)});
		}


		[class^=button] {
			opacity: 0;
			transform: translate(0, #{vw(100)});
		}

		&-active {
			.panel,
			[class^=button],
			.avatar,
			.top {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.panel {
				--delay: 0.2s;
			}

			.avatar { --delay: calc(0.2s + (var(--i, 0) * 0.025s)); }

			[class^=button] { --delay: 0.4s; }
		}
	}
}