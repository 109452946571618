.polls-tab {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	flex-direction: column;
	padding: vw(32) vw(21) vw(41) vw(41);
	background: var(--glass-gradient);
	backdrop-filter: blur(vw(20));
	z-index: 999;

	.back-icon {
		width: vw(24);
		height: auto;
	}

	.wrapper {
		width: 100%;
		border-radius: vw(17);
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		padding-right: vw(20);

		&::-webkit-scrollbar-button:start:increment {
			width: vw(20);
			display: block;
		}

		&::-webkit-scrollbar-button:end:increment {
			width: vw(20);
			display: block;
		}

		.title {
			font-family: var(--font-family-title);
			font-size: vw(24);
			font-weight: 800;
			line-height: 1.1;
			margin-bottom: vw(5);
			text-align: center;
		}

		.subtext {
			font-family: var(--font-family-title);
			font-size: vw(20);
			font-weight: 300;
			line-height: 1.1;
			text-align: center;
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		margin-top: vw(20);
		gap: vw(16);
	}

	&.enter,
	&.appear {
		transform: translate(100%, 0);

		.single-poll {
			opacity: 0;
			transform: translate(vw(40), 0);
		}
		
		&-active {
			transform: translate(0, 0);
			transition: transform 0.6s $easeOutExpo;

			.single-poll {
				opacity: 1;
				transform: translate(0, 0);
				--delay: calc(var(--i) * 0.1s + 0.2s);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0.3s), transform 0.6s $easeOutCubic var(--delay, 0.3s);
			}
		}
	}

	&.exit {
		transform: translate(0, 0);
		
		&-active {
			transform: translate(100%, 0);
			transition: transform 0.3s $easeInCubic;
		}
	}
}

.single-poll {
	position: relative;
	border-radius: vw(17);
	border: vw(2) solid var(--glass-transparent);
	background: var(--glass-transparent-65);
	padding: vw(18) vw(18) vw(10) vw(18);
	font-family: var(--font-family-title);

	&.completed {
		border-radius: vw(13.5);
		background: linear-gradient(92deg, rgba(231, 231, 231, 0.50) -4.09%, rgba(159, 159, 159, 0.50) 103.94%);
		backdrop-filter: blur(vw(9));
		border: none;

		color: rgba(#484848, 0.50);
	}

	.circle-close {
		position: absolute;
		top: vw(8);
		right: vw(13);
	}

	.question-tabs {
		display: flex;
		height: vw(8);
		gap: vw(14);
		margin-top: vw(6);

		.tab {
			flex: 1 1 100%;
			width: auto;
			height: 100%;
			background: white;
			border-radius: vw(16);
			opacity: 0.4;

			transition: opacity 0.3s ease;

			&.active {
				opacity: 1;
			}
		}
	}

	.top {
		width: calc(100% + vw(36));
		border-bottom: vw(2) solid white;
		text-transform: uppercase;
		padding: vw(10) vw(15);
		display: flex;
		justify-content: space-between;
		margin: vw(-18) vw(-18) vw(10) vw(-18);

		font-size: vw(11);
		font-weight: 600;
		line-height: 1.1;
	}

	.poll-title {
		font-size: vw(13);
		font-weight: 800;
		line-height: 1.5;
		letter-spacing: vw(-0.138);
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: vw(9);
		}
	}

	.poll-explanation {
		font-family: var(--font-family-title);
		font-size: vw(9);
		font-style: normal;
		font-weight: 600;
		line-height: 1.5;
		letter-spacing: vw(-0.11);
		text-transform: uppercase;
		margin-bottom: vw(8);
	}

	.poll-description {
		font-size: vw(16);
		font-weight: 300;
		line-height: 1.1;
		margin-bottom: vw(16);
	}

	[class^=button] {
		transition: background 0.3s ease;
		
		&.disabled {
			opacity: 1;
			background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(0, 0, 0, 0.30);
			background-blend-mode: color-dodge, normal;
		}
	}

	&.enter,
	&.appear {
		opacity: 0;
		transform: translate(0, vw(40));

		.top {
			overflow: hidden;
		}

		.top .text,
		.top .reward,
		.top .question-tabs,
		.poll-explanation {
			opacity: 0;
			transform: translate(0, vw(20));
		}

		&-active {
			&,
			.top .text,
			.top .reward,
			.top .question-tabs,
			.poll-explanation {
				opacity: 1;
				transform: translate(0, 0);
				transition: transform 0.6s $easeOutExpo var(--delay, 0.3s), opacity 0.6s $easeOutExpo var(--delay, 0.3s);
			}

			.top .text { --delay: 0.40s }
			.top .reward { --delay: 0.45s }
			.top .question-tabs { --delay: 0.50s }
			.poll-explanation { --delay: 0.55s }
		}
	}

	.content {
		position: relative;
		opacity: 0;

		.submitting-msg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0;
			pointer-events: none;
		}

		--anim-spacing: 0.6s;

		&.quick-enter,
		&.quick-appear {
			--anim-spacing: 0s;
		}

		&.appear-done,
		&.enter-done,
		&.quick-enter-done,
		&.quick-appear-done {
			opacity: 1;
		}

		&.enter,
		&.quick-enter,
		&.appear,
		&.quick-appear {
			opacity: 1;
			overflow: hidden;
			
			.poll-description,
			.option,
			.btn-ctn {
				opacity: 0;
				transform: translate(0, vw(20));
			}

			&-active {
				.poll-description,
				.option,
				.btn-ctn {
					opacity: 1;
					transform: translate(0, 0);
					transition: transform 0.6s $easeOutExpo var(--delay, var(--anim-spacing)), opacity 0.6s $easeOutExpo var(--delay, var(--anim-spacing));
				}

				.option {
					--delay: calc(var(--i) * 0.1s + var(--anim-spacing));
				}

				.btn-ctn { --delay: calc(var(--i) * 0.1s + var(--anim-spacing)); }
			}
		}
	}

	&.submitting {
		.poll-explanation {
			opacity: 0;
			transition: opacity 0.3s ease;
		}

		.content {
			pointer-events: none;

			.poll-description,
			.poll-options,
			.btn-ctn {
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}

		.submitting-msg {
			opacity: 1;
			transition: opacity 0.3s ease;
		}
	}
}

.poll-options {
	&.one-to-five {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: vw(6);
		margin-bottom: vw(24);
		
		.option {
			position: relative;
			display: flex;
			width: vw(46);
			height: vw(46);
			align-items: center;
			justify-content: center;
			border-radius: vw(2.5);
			outline: vw(1) solid var(--glass-transparent);
			outline-offset: vw(-1);
			background: var(--glass-gradient);
			backdrop-filter: blur(vw(1.5));
			overflow: hidden;
			cursor: pointer;

			&.selected:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: vw(3) solid;
				border-image-source: var(--rainbow-gradient-faded);
				border-image-slice: 1;
				border-width: vw(3);
				z-index: 1;
			}

			.thumbs-up-icon {
				transform: rotate(var(--angle, 0deg));
				color: var(--icon-color);
			}

			.shadow {
				filter: drop-shadow(0px vw(4) vw(4) rgba(0, 0, 0, 0.25));
			}

			$colors: (
				1: #2DC427,
				2: #B3D21F,
				3: #FFD119,
				4: #F89118,
				5: #EA1817
			);
			@for $i from 1 through 5 {
				&.option-#{$i} .thumbs-up-icon {
					--angle: #{($i - 1) * -45}deg;
					--icon-color: #{map-get($colors, $i)};
				}
			}
		}
	}

	&.default {
		display: flex;
		flex-direction: column;
		gap: vw(8);

		margin-bottom: vw(24);

		.option {
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			text-align: center;
			min-height: vw(40);
			background: white;
			border-radius: vw(80);
			padding: vw(10) vw(20);

			font-family: var(--font-family-title);
			font-size: vw(12);
			font-style: normal;
			font-weight: 600;
			line-height: vw(14);
			text-transform: none;

			&.selected {
				color: white;
				background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), #9FA0A2;
				background-blend-mode: color-dodge, normal;
			}
		}
	}
}