.scan-rewards {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 999999;
	overflow: hidden;

	background: rgba(black, 0.25);
	backdrop-filter: blur(vw(10));

	.panel {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;

		width: vw(266);
		padding: vw(20);
		color: var(--color-text-primary);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid white;
			background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
			backdrop-filter: blur(vw(25));
			border-radius: vw(20);
			z-index: -1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 200vw;
			height: 200vh;
			background: rgba(0, 0, 0, 0.5);
			backdrop-filter: blur(vw(25));
			z-index: -2;
		}

		.close-icon {
			position: absolute;
			top: vw(14);
			right: vw(14);
			width: vw(24);
			height: auto;
			cursor: pointer;
		}

		.title {
			font-weight: 500;
			font-size: vw(22);
			line-height: 1;

			&:not(:last-child) {
				margin-bottom: vw(24);
			}
		}

		.xp-bar {
			width: 100%;
			line-height: 1;
			background: var(--rainbow-gradient);
			padding: vw(12) vw(20);
			border-radius: vw(10);
			margin-bottom: vw(17);
			color: white;
			font-size: vw(16);
		}

		.subtitle {
			font-weight: 500;
			font-size: vw(14);
			line-height: 1.1;
			margin-bottom: vw(8);
		}

		.text {
			font-size: vw(14);
			margin-bottom: vw(20);
		}
	}

	&.appear,
	&.enter {
		opacity: 0;
		transform: translate(0, #{vw(40)});

		.panel > * {
			opacity: 0;
			transform: translate(0, #{vw(20)});
		}

		&-active {
			& {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			& .panel > * {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.panel :nth-child(1) { --delay: 0.2s; }
			.panel :nth-child(2) { --delay: 0.3s; }
			.panel :nth-child(3) { --delay: 0.4s; }
			.panel :nth-child(4) { --delay: 0.5s; }
			.panel :nth-child(5) { --delay: 0.5s; }
			.panel :nth-child(6) { --delay: 0.6s; }
		}
	}
}