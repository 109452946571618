.animated-bottle-cap {
	--size: #{vw(224)};
	width: var(--size);
	height: var(--size);
	overflow: hidden;

	display: grid;

	video {
		grid-row: 1;
		grid-column: 1;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}