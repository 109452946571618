.world-map {
	position: relative;

	.mapboxgl-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(var(--vh, 1vh) * 100);
	}
	
	.mapboxgl-marker {
		pointer-events: none;
		user-select: none;

		&.hide {
			pointer-events: none;
		}

		&:not(.hide) {
			.hotspot {
				pointer-events: all;
			}
		}

		&.active {
			z-index: 10 !important;
		}
	}

	.marker {
		pointer-events: none;
		position: relative;
		bottom: vw(-20);
		width: vw(120);
		height: vw(120);
		z-index: 2;
	}

	.debug-center {
		position: absolute;
		top: vw(110);
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 10;
	}

	.cluster-count {
		// pointer-events: none;
		position: absolute;
		bottom: vw(32);
		left: 50%;
		width: vw(35);
		height: vw(35);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: vw(20);
		font-weight: 900;
		border-radius: 9999px;
		transform: translate(-50%, 0);
		color: #484848;
		z-index: 2;
	}

	.center-position {
		position: fixed;
		bottom: vw(32);
		right: vw(23);
		display: flex;
		align-items: center;
		justify-content: center;
		width: vw(58);
		height: vw(58);
		background: #FFFFFF;
		border-radius: 9999px;
		box-shadow: vw(0) vw(2) vw(6) rgba(0, 0, 0, 0.3);
		transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
		transform: translate(0, 0);
		
		&.offset {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		.target-icon {
			width: vw(30);
			height: vw(30);
		}

		.warning-icon {
			color: #C1C4CA;
			width: vw(32);
			height: auto;
		}
	}

	.help {
		position: fixed;
		top: vw(100);
		left: vw(23);
		display: flex;
		align-items: center;
		justify-content: center;
		width: vw(30);
		height: vw(30);
		background: #FFFFFF;
		border-radius: 9999px;
		box-shadow: vw(0) vw(2) vw(6) rgba(0, 0, 0, 0.3);
		transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
		transform: translate(0, 0);

		.question-mark-icon {
			position: relative;
			left: vw(0.5);
			width: vw(15);
			height: vw(15);
		}

		.warning-icon {
			color: #C1C4CA;
			width: vw(32);
			height: auto;
		}
	}

	.popup {
		position: absolute;
		bottom: vw(48);
		left: 50%;
		width: vw(125);
		transform: translate(-50%, 0);
		z-index: 1;
		padding: vw(8) vw(8) vw(35) vw(8);
		gap: vw(4);
		background: linear-gradient(124.01deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.3) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.7) 72.44%);
		backdrop-filter: blur(5px);
		border: vw(1) solid white;
		border-radius: vw(8);
		color: #484848;

		.title {
			font-family: var(--font-family-title);
			font-weight: 700;
			font-size: vw(13);
			line-height: vw(16);
		}

		.address {
			font-family: var(--font-family-text);
			font-style: normal;
			font-weight: 400;
			font-size: vw(10);
			line-height: vw(12);
		}

		&.enter,
		&.appear {
			opacity: 0;
			transform: translate(-50%, vw(100));

			&-active {
				opacity: 1;
				transform: translate(-50%, 0);
				transition: opacity 0.3s $easeOutCubic, transform 0.3s $easeOutCubic;
			}
		}
	}

	.poi {
		.icon,
		.animated-bottle-cap {
			position: absolute;
			bottom: vw(34);
			left: calc(50% + #{vw(1)});
			transform: translate(-50%, 0);
			width: vw(32);
			height: auto;
			z-index: 3;
		}

		.inner {
			transform-origin: 50% 100%;
			
			&.enter,
			&.appear {
				opacity: 0;
				transform-origin: 50% 100%;
				transform: scale(0, 0);

				&-active {
					opacity: 1;
					transform: scale(1, 1);
					transition: opacity 0.3s $easeOutCubic, transform 0.3s $easeOutCubic;
				}
			}

			&.exit {
				opacity: 1;
				transform-origin: 50% 100%;
				transform: scale(1, 1);

				&-active {
					opacity: 0;
					transform: scale(0, 0);
					transition: opacity 0.3s $easeInCubic, transform 0.3s $easeInCubic;
				}
			}
		}

		&.unclaimed {
			z-index: 10 !important;
		}

		&:not(.unclaimed) {
			.icon,
			.animated-bottle-cap {
				width: vw(16);
				bottom: vw(17);
			}

			.marker {
				bottom: vw(-10);
				width: vw(60);
				height: vw(60);
			}

			.hotspot {
				visibility: hidden;
			}
		}

		.hotspot {
			position: absolute;
			bottom: 0;
			left: 50%;
			width: vw(50);
			height: vw(75);
			transform: translate(-50%, 0);
			z-index: 2;
			border-radius: vw(25);
			cursor: pointer;
			// background: red;
			// opacity: 0.1;
		}
	}


	.player-position {
		z-index: 5 !important;

		img {
			position: relative;
			bottom: vw(0);
			z-index: 1;
			width: vw(80);
			height: vw(80);
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: vw(8);
			height: vw(5);
			transform: translate(-50%, 0) scale(1, -1);
			background: rgba(black, 0.5);

			//triangle pointing down
			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		}

		&:after {
			content: '';
			position: absolute;
			bottom: vw(6);
			left: 50%;
			width: vw(54);
			height: vw(22);
			transform: translate(-50%, 0);
			background: rgba(black, 0.2);
			border: vw(1) solid white;
			border-radius: 100%;
		}
	}

	.show-missions-cta {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: vw(107);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		gap: vw(10);
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 61.21%);

		font-size: vw(16);
		font-weight: bold;

		.small-chevron-icon {
			width: vw(9);
			height: auto;
		}
	}

	.out-of-bounds {
		position: absolute;
		top: var(--header-clear);
		left: calc((100vw - #{vw(240)}) / 2);
		width: vw(240);
		text-align: center;
		padding: vw(6);
		border-radius: vw(16);
		border: vw(1) solid white;
		font-size: vw(10);
		
		background: var(--glass-transparent);
		backdrop-filter: blur(5px);

		small {
			font-size: 75%;
		}
	}

	.debug-panel {
		position: absolute;
		top: calc(100% + #{vw(5)});
		left: 50%;
		transform: translate(-50%, 0);
		padding: vw(3);
		border-radius: vw(6);
		border: vw(1) solid white;
		font-size: vw(8);
		line-height: 1;
		text-align: center;
		white-space: pre;
		
		background: var(--glass-transparent);
		backdrop-filter: blur(5px);
	}
}

.world-map .missions-list {
	position: fixed;
	bottom: vw(44);
	left: 50%;
	width: vw(320);
	height: auto;
	padding: vw(20);
	transform: translate(-50%, 0);
	border-radius: vw(24);
	border: vw(1) solid white;
	z-index: 20;
	
	background: var(--glass-gradient);
	backdrop-filter: blur(5px);

	.missions {
		display: grid;
	}

	.nav {
		display: flex;
		gap: vw(17);
		justify-content: flex-end;
		margin-bottom: vw(4);

		.btn {
			width: vw(27);
			height: vw(27);
			border-radius: 100%;
			background: #d9d9d9;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}

			&.prev {
				.small-chevron-icon {
					transform: rotate(-90deg);
				}
			}

			&.next {
				.small-chevron-icon {
					transform: rotate(90deg);
				}
			}
		}
	}

	.mission {
		grid-row: 1;
		grid-column: 1;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;
		display: flex;
		flex-direction: column;

		&.active {
			opacity: 1;
			pointer-events: all;
		}

		.title {
			font-weight: 700;
			font-size: vw(22);
			line-height: vw(26);
			margin-bottom: vw(12);
		}

		.description {
			font-weight: 400;
			font-size: vw(14);
			line-height: vw(17);
			margin-bottom: vw(34);
		}

		.subtitle {
			font-weight: 600;
			font-size: vw(14);
			line-height: vw(17);
		}

		.clue-description {
			font-weight: 400;
			font-size: vw(14);
			line-height: vw(17);
			margin-bottom: vw(11);
		}

		.button {
			width: 100%;
			padding-top: vw(10);
			padding-bottom: vw(10);
			font-size: vw(14);
			margin-top: auto;
		}

		.clues {
			display: flex;
			align-items: center;
			gap: vw(11);
			margin-bottom: vw(15);

			.clue {
				width: vw(36);
				height: vw(36);
				border-radius: 100%;
				border: vw(2) solid #CBCBCB;

				img {
					width: 100%;
					opacity: 0.3;
				}

				&.completed {
					border-color: transparent;
					
					img {
						opacity: 1;
					}
				}

				&.active {
					border-color: var(--color-treasure-hunt);
				}
			}

			.clues-left {
				margin-left: auto;
				font-size: vw(12);
				font-weight: 600;
			}
		}
	}

	&.enter,
	&.appear {
		transform: translate(-50%, 110%);

		&-active {
			opacity: 1;
			transform: translate(-50%, 0);
			transition: transform 0.6s $easeOutCubic;
		}
	}

	&.exit {
		transform: translate(-50%, 0);

		&-active {
			transform: translate(-50%, 110%);
			transition: transform 0.3s $easeInOutCubic;
		}
	}
}