.text-field {
	position: relative;
	line-height: 1.16;
	margin-block-end: 1em;

	input {
		width: 100%;
		background-color: transparent;
		border: 1px solid var(--color-text-primary);
		border-radius: 8px;
		padding: 1em;
		outline: none;
		font-weight: $font-weight-medium;
	}

	label {
		display: inline-block;
		margin-block-end: 0.5em;
		font-size: vw(14);
	}
}

.checkbox-field {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-block-end: vw(20);
	}
	
	input {
		@include visually-hidden;

		&:checked + label::after {
			opacity: 1;
		}

		&[aria-invalid="true"] + label {
			color: red;
		}
	}

	label {
		padding-inline-start: 2.25em;
		position: relative;
		font-size: vw(24);
		cursor: pointer;
		font-weight: $font-weight-medium;

		&::before {
			content: '';
			width: 1.5em;
			height: 1.5em;
			border: 2px solid var(--color-accent-primary);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}

		&::after {
			content: '';
			width: 0.8em;
			height: 0.8em;
			background-color: var(--color-accent-primary);
			position: absolute;
			top: 50%;
			left: 0.35em;
			transform: translate(0, -50%);
			opacity: 0;
		}
	}
}

.error-msg {
	display: inline-block;
	color: red;
	margin-block: 0.5em;
}
