.you {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100 - var(--menu-height));
	padding: vw(130) vw(20) vw(0) vw(20);
	width: 100vw;

	.tab-btns {
		position: fixed;
		top: var(--header-clear);
		display: grid;
		width: calc(100% - vw(40));
		grid-template-columns: repeat(3, 1fr);
		gap: vw(7);

		.tab-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			font-size: vw(10);
			font-weight: 700;
			line-height: 1;
			padding: vw(10) vw(10);
			border-radius: vw(36);
			border: vw(2) solid var(--icon-color, #FFF);
			background: var(--glass-gradient);

			&:not(.active) {
				opacity: 0.5;
			}
		}
	}

	.tab-content {
		display: flex;
		height: 100%;
	}

	.panel {
		width: 100%;
		display: block;
		border-radius: vw(17);
		border: vw(2) solid white;
		background: var(--glass-gradient);
		padding: vw(20);
	}

	.tab {
		.title {
			display: block;
			font-family: var(--font-family-title);
			font-size: vw(24);
			font-weight: 800;
			line-height: 1.1;
			margin-bottom: vw(15);
		}

		.subtext {
			font-family: var(--font-family-title);
			font-size: vw(20);
			font-weight: 300;
			line-height: 1.1;
		}
	}

	.tab.profile-tab {
		position: relative;
		left: vw(-20);
		width: calc(100vw - #{vw(10)});
		overflow-x: hidden;
		overflow-y: auto;
		flex: 0 0 auto;
		padding-left: vw(20);
		padding-right: vw(10);

		.t-and-c {
			position: absolute;
			top: vw(25);
			right: vw(30);
			font-size: vw(10);
			font-weight: 300;
			line-height: vw(12);
			text-align: right;
		}

		.panel {
			position: relative;
			margin-bottom: vw(15);
		}

		.title {
			font-size: vw(18);
		}

		&.appear,
		&.enter {
			.panel {
				opacity: 0;
				transform: translate(0, #{vw(40)});
			}

			&-active {
				.panel {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.panel.stats { --delay: 0.1s; }
				.panel.rank { --delay: 0.2s; }
				.panel.badges { --delay: 0.3; }
				.panel.logout { --delay: 0.3; }
			}
		}
	}

	.panel.stats {
		padding: vw(20) 0 0 0;

		.title {
			margin-bottom: vw(20);
			padding: 0 vw(20);
		}

		.stats-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: vw(15);
			width: vw(350);
			padding: 0 vw(20) vw(20) vw(20);

			&::-webkit-scrollbar-button:start:increment {
				width: vw(20);
				display: block;
			}
	
			&::-webkit-scrollbar-button:end:increment {
				width: vw(20);
				display: block;
			}
		}

		.stat {
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 0 0 vw(55);
			text-align: center;

			.value-ctn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: vw(5);
				
				width: vw(55);
				height: vw(55);

				border-radius: 3.197px;
				border: vw(2) solid var(--glass-transparent);
				background: var(--glass-gradient);
				backdrop-filter: blur(vw(2));

				.value {
					font-family: var(--font-family-title);
					font-size: vw(12);
					font-weight: 600;
				}
			}

			.name {
				text-align: center;
				font-family: var(--font-family-title);
				font-size: vw(9);
				font-weight: 600;
			}
		}
	}

	.panel.profile {
		.card {
			display: flex;
			align-items: center;
			gap: vw(13);

			.avatar {
				position: relative;
				width: vw(66);
				flex: 0 0 auto;
				z-index: 1;

				.edit-icon {
					width: vw(12);
					height: auto;
					position: absolute;
					top: vw(5);
					right: vw(5);
				}

				.bubble {
					position: absolute;
					top: 0;
					left: 0;
					--size: #{vw(66)};
					z-index: -1;
				}
			}

			.name {
				font-family: var(--font-family-title);
				font-size: vw(12);
				font-weight: 800;
				line-height: 1.1;
				margin-bottom: vw(5);
			}

			.text {
				font-family: var(--font-family-title);
				font-size: vw(10);
				font-weight: 300;
				line-height: 1.2;

				small {
					display: block;
					margin-top: vw(10);
					font-size: 80%;
				}
			}
		}
	}

	.panel.badges {
		display: flex;
		flex-direction: column;
	}

	.badges-grid {
		margin-top: vw(20);
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;
	}

	.badge {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		.image {
			display: grid;
			width: vw(67);
			height: vw(67);

			img {
				grid-row: 1;
				grid-column: 1;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			.icon {
				filter: drop-shadow(0px 3.61552px 3.61552px rgba(0, 0, 0, 0.25));
				position: relative;
				top: vw(-2);
				left: vw(2);
			}
		}

		.name {
			font-family: var(--font-family-title);
			font-size: vw(13);
			font-style: normal;
			font-weight: 800;
			line-height: normal;
		}

		.description {
			font-family: var(--font-family-title);
			font-size: vw(11);
			font-weight: 300;
			line-height: 1.1;
		}

		&.owned {
			.check {
				position: absolute;
				top: vw(3);
				right: vw(10);
				width: vw(20);
				height: vw(20);
				display: flex;
				align-items: center;
				justify-content: center;

				&:before {
					content:'';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					background: #1AD922;
					color: white;
				}

				.checkmark-icon {
					width: vw(13);
					height: auto;
					position: relative;
					z-index: 1;
					color: white;
				}
			}
		}
	}

	.panel.rank {
		.title {
			margin-bottom: vw(20);
		}
	}

	.tab.leaderboard-tab {
		display: flex;
		flex-direction: column;
		border-radius: vw(17);
		border: vw(2) solid white;
		background: var(--glass-gradient);
		width: 100%;
		height: calc(100% - #{vw(20)});
		padding: vw(20);
		text-align: center;

		.leaderboard {
			margin-top: vw(20);
			display: grid;
			grid-template-columns: 1fr;
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			padding-right: vw(10);
			gap: vw(7);
		}

		.subtext {
			font-size: vw(16);
		}

		&.appear,
		&.enter {
			opacity: 0;
			transform: translate(0, #{vw(40)});

			.title,
			.subtext,
			.leaderboard-entry {
				opacity: 0;
				transform: translate(0, #{vw(20)});
			}

			&-active {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);

				.title,
				.subtext,
				.leaderboard-entry {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.title { --delay: 0.1s; }
				.subtext { --delay: 0.2s; }
				.leaderboard-entry { --delay: calc(var(--i) * 0.05s + 0.3s); }
			}
		}
	}

	.leaderboard-entry {
		position: relative;
		overflow: hidden;
		display: grid;
		grid-template-columns: vw(36) 1fr auto;
		align-items: center;
		padding: 0 vw(10);
		z-index: 1;
		width: 100%;
		height: vw(50);
		border-radius: 13.513px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: vw(2) solid var(--glass-transparent);
			background: var(--glass-gradient);
			backdrop-filter: blur(vw(9));
			z-index: -1;
		}

		.rank {
			font-size: vw(17);
			font-weight: 700;
			line-height: 1;

			img {
				width: vw(36);
				height: vw(36);
				object-fit: contain;
			}
		}

		.name, .value {
			font-family: var(--font-family-title);
			font-size: vw(12);
			font-weight: 600;
			line-height: 1;

			img {
				display: inline-block;
				width: vw(36);
				height: vw(36);
				object-fit: contain;
				margin-right: vw(5);
			}
		}

		.name {
			text-align: left;
			padding-left: vw(6);
		}

		&.you .name {
			padding-left: vw(0);
		}

		.value {
			text-align: right;
		}

		.avatar {
			position: relative;
			transform: scale(1.5);
		}

		&.glow-gold:after,
		&.glow-silver:after,
		&.glow-bronze:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: vw(113);
			height: 100%;
			backdrop-filter: blur(vw(9));
			z-index: -2;
		}

		&.glow-gold:after {
			background: linear-gradient(93deg, #FFE86C 3.65%, #DADADA 98.54%);
		}

		&.glow-silver:after {
			background: linear-gradient(93deg, #A2A2A2 3.61%, #DADADA 95.64%);
		}

		&.glow-bronze:after {
			background: linear-gradient(93deg, #ECA96C 3.77%, #DADADA 102.49%);
		}
	}

	.panel.debug {
		display: flex;
		flex-direction: column;
		gap: vw(20);
	}

	.tab.badges-tab {
		display: flex;
		flex-direction: column;
		border-radius: vw(17);
		border: vw(2) solid white;
		background: var(--glass-gradient);
		width: 100%;
		height: calc(100% - #{vw(20)});
		padding: vw(20);
		text-align: center;

		.badges-grid {
			grid-template-columns: repeat(2, 1fr);
			gap: vw(20) vw(30);
			align-items: flex-start;
			margin: vw(25) vw(-20) 0;
			padding: 0 vw(20);
			
			.image {
				width: vw(115);
				height: vw(115);
			}

			.description {
				margin-top: vw(5);
			}

			.badge {
				.image {
					opacity: 0.25;
				}

				&.owned {
					.image {
						opacity: 1;
					}

					.check {
						top: vw(16);
						right: vw(24);
					}
				}
			}
		}

		&.appear,
		&.enter {
			opacity: 0;
			transform: translate(0, #{vw(40)});

			.badge,
			.subtext,
			.badge {
				opacity: 0;
				transform: translate(0, #{vw(20)});
			}

			&-active {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);

				.title,
				.subtext,
				.badge {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.title { --delay: 0.1s; }
				.subtext { --delay: 0.2s; }
				.badge { --delay: calc(var(--i) * 0.05s + 0.3s); }
			}
		}
	}

	&.appear,
	&.enter {
		opacity: 0;
		transform: translate(0, #{vw(20)});

		&-active {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}
}

.es {
	.you {
		.tab-btns {
			.tab-btn:nth-child(2) {
				font-size: vw(8);
			}
		}
	}
}