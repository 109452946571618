@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
	font-family: "TCCC-UnityHeadline";
	src:
		url("../fonts/TCCC-UnityHeadline-Light.woff2") format("woff2"),
		url("../fonts/TCCC-UnityHeadline-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityHeadline";
	src:
		url("../fonts/TCCC-UnityHeadline-Medium.woff2") format("woff2"),
		url("../fonts/TCCC-UnityHeadline-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityCondensed";
	src:
		url("../fonts/TCCC-UnityCondensed-Medium.woff2") format("woff2"),
		url("../fonts/TCCC-UnityCondensed-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityHeadline";
	src:
		url("../fonts/TCCC-UnityHeadline-Black.woff2") format("woff2"),
		url("../fonts/TCCC-UnityHeadline-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityText";
	src:
		url("../fonts/TCCC-UnityText-Medium.woff2") format("woff2"),
		url("../fonts/TCCC-UnityText-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityText";
	src:
		url("../fonts/TCCC-UnityText-Light.woff2") format("woff2"),
		url("../fonts/TCCC-UnityText-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityText";
	src:
		url("../fonts/TCCC-UnityText-Black.woff2") format("woff2"),
		url("../fonts/TCCC-UnityText-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityText";
	src:
		url("../fonts/TCCC-UnityText-Bold.woff2") format("woff2"),
		url("../fonts/TCCC-UnityText-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "TCCC-UnityText";
	src:
		url("../fonts/TCCC-UnityText-Regular.woff2") format("woff2"),
		url("../fonts/TCCC-UnityText-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
