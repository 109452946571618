.circle-close {
	width: vw(27);
	height: vw(27);
	background: var(--color-text-primary);
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: vw(9);
		height: auto;
		color: white;
	}
}