.auth {
	min-height: calc(100 * var(--vh));
}

.ftue {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: vw(25) vw(45) vw(25);

	.top {
		position: relative;
		width: vw(300);
		height: vw(300);
		transform-origin: bottom center;
		transition: transform 0.3s ease;
		flex: 0 0 auto;
		margin-top: vw(20);
		margin-bottom: vw(-50);
	}

	.btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: vw(20) vw(25) vw(0);
	}

	.cta.start {
		margin-left: auto;
		margin-right: auto;
	}

	.panel {
		padding: vw(16);
		border: vw(2) solid white;
		border-radius: vw(22);
		background: rgba(white, 0.3);
		backdrop-filter: blur(vw(10));
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: vw(300);
		margin: 0 auto;

		.text {
			font-family: var(--font-family-title);
			font-size: vw(17);
			font-weight: 800;
			line-height: vw(20);
		}
	}

	.bubble {
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	.bubble-ctn {
		display: grid;
		align-items: center;
		justify-items: center;
		aspect-ratio: 1;

		> * {
			grid-row: 1;
			grid-column: 1;
		}
	}

	.logo {
		width: 100%;
		height: vw(111);
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;

		img {
			width: auto;
			height: 100%;
		}
	}

	.bubble-1 {
		z-index: 3;

		.city {
			position: relative;
			left: vw(4);
			width: vw(180);
			height: auto;
		}
	}

	.bubble-2 {
		position: absolute;
		bottom: vw(166);
		left: vw(-47);

		width: vw(158);
		z-index: 2;

		.animated-bottle-cap {
			--size: #{vw(123)};
			position: relative;
			top: vw(-2);
			left: vw(3);
		}
	}

	.bubble-3 {
		position: absolute;
		bottom: vw(17);
		right: vw(-72);
		width: vw(170);
		z-index: 2;

		.gift-card {
			width: vw(102);
			height: auto;
		}
	}

	&.appear,
	&.enter {
		overflow: hidden;
		.panel,
		.logo,
		.btns {
			opacity: 0;
			transform: translate(0, #{vw(40)});
		}
		
		.bubble-1,
		.bubble-2,
		.bubble-3 {
			opacity: 0;
			transform: translate(0, #{vw(40)}) scale(0.8);
			transform-origin: 50% 75%;
		}

		&-active {
			.bubble-1,
			.bubble-2,
			.bubble-3,
			.panel,
			.logo,
			.btns {
				opacity: 1;
				transform: translate(0, 0) scale(1);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.bubble-1 { --delay: 0.2s; }
			.bubble-2 { --delay: 0.3s; }
			.bubble-3 { --delay: 0.4s; }

			.panel { --delay: 0.4s; }
			.btns { --delay: 0.5s; }
		}
	}
}