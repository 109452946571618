.barcode-scanner-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 999;
	overflow: hidden;

	background: rgba(black, 0.9);
	backdrop-filter: blur(vw(10));

	.intro {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;

		width: vw(266);
		padding: vw(20);
		color: var(--color-text-primary);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid white;
			background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
			backdrop-filter: blur(vw(25));
			border-radius: vw(20);
			z-index: -1;
		}

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	transform: translate(-50%, -50%);
		// 	width: 200vw;
		// 	height: 200vh;
		// 	background: rgba(0, 0, 0, 0.5);
		// 	backdrop-filter: blur(vw(25));
		// 	z-index: -2;
		// }

		.close-icon {
			position: absolute;
			top: vw(14);
			right: vw(14);
			width: vw(24);
			height: auto;
			cursor: pointer;
		}

		.title {
			font-weight: 500;
			font-size: vw(25);
			line-height: vw(30);
		}

		.subtitle {
			font-weight: 500;
			font-size: vw(19);
			line-height: vw(23);
			margin-bottom: vw(30);
		}

		ol {
			font-weight: 400;
			font-size: vw(15);
			line-height: vw(18);
			margin-bottom: vw(40);

			li {
				margin-bottom: 1em;
			}
		}

		&.appear,
		&.enter {
			opacity: 0;
			transform: translate(-50%, -50%) translate(0, #{vw(40)});
			overflow: hidden;

			.subtitle,
			.title,
			ol li {
				opacity: 0;
				transform: translate(0, #{vw(20)});
			}

			&-active {
				--delay: 0.2s;
				opacity: 1;
				transform: translate(-50%, -50%) translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);

				.subtitle,
				.title,
				ol li {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.title,
				.subtitle,
				ol li,
				.button { --delay: calc(var(--i, 0) * 0.1s + 0.2s) }
			}
		}
	}

	.allow-camera-access {
		.title {
			font-weight: 500;
			font-size: vw(25);
			line-height: vw(30);
			margin-bottom: vw(20);
		}

		.subtitle {
			font-weight: 500;
			font-size: vw(16);
			line-height: vw(18);
			margin-bottom: vw(20);
		}
	}

	.barcode-ctn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		#html5qr-code-full-region {
			height: 100%;
		}

		video {
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;

			&.appear,
			&.enter {
				opacity: 0;

				&-active {
					opacity: 1;
					transition: opacity 0.3s $easeOutCubic;
				}
			}
		}

		// canvas, video {
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	min-width: 100%;
		// 	min-height: 100%;
		// 	transform: translate(-50%, -50%);
		// }

		// video {
		// 	object-fit: cover;
		// }

		// canvas {
		// 	position: relative;
		// 	z-index: 1;
		// }
	}

	.tip {
		position: absolute;
		top: vw(20);
		left: calc((100vw - #{vw(240)}) / 2);
		width: vw(240);
		text-align: center;
		padding: vw(6);
		border-radius: vw(16);
		border: vw(1) solid white;
		font-size: vw(10);
		
		background: var(--glass-transparent);
		backdrop-filter: blur(5px);

		small {
			font-size: 75%;
		}

		&.appear,
		&.enter {
			transform: scale(0.8);
			opacity: 0;

			&-active {
				opacity: 1;
				transform: scale(1);
				transition: opacity 0.3s $easeOutCubic var(--delay, 0.3s), transform 0.3s $easeOutCubic var(--delay, 0.3s);
			}
		}
	}

	.scan-region {
		position: relative;
		width: vw(310);
		height: vw(200);
		margin-top: auto;
		margin-bottom: auto;

		.barcode {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 60%;
			height: auto;

			transform: translate(-50%, -50%);
			opacity: 0.2;
		}

		.corner {
			position: absolute;
			width: vw(50);
			height: vw(50);
			border-top-left-radius: vw(30);
			border-top: vw(11) solid white;
			border-left: vw(11) solid white;

			&.top-left {
				top: 0;
				left: 0;
			}

			&.top-right {
				top: 0;
				right: 0;
				transform: rotate(90deg);
			}

			&.bottom-left {
				bottom: 0;
				left: 0;
				transform: rotate(-90deg);
			}

			&.bottom-right {
				bottom: 0;
				right: 0;
				transform: rotate(180deg);
			}
		}

		.scanning {
			position: absolute;
			display: grid;
			align-items: center;
			justify-items: center;
			bottom: vw(24);
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 1;

			> * {
				grid-row: 1;
				grid-column: 1;
			}

			.text {
				position: relative;
				display: flex;
				width: 100%;
				height: 100%;
				align-items: center;
				padding: vw(10) vw(20);
				z-index: 2;
				color: white;
			}
		}
	}

	.content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		padding: vw(50) vw(30) vw(20) vw(30);

		.close-icon {
			position: absolute;
			top: vw(14);
			right: vw(14);
			width: vw(24);
			height: auto;
			cursor: pointer;
		}
		
		.select {
			margin-top: vw(20);
			flex: 0 0 auto;
		}

		.button {
			margin-bottom: vw(40);
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: vw(160);
			gap: vw(10);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);

			font-size: vw(16);
			font-weight: bold;
			z-index: -1;
		}

		&.appear,
		&.enter {
			.mission-panel,
			.scan-region,
			.select {
				transform: scale(0.8);
				opacity: 0;
			}

			&-active {
				.mission-panel,
				.select,
				.scan-region {
					opacity: 1;
					transform: scale(1);
					transition: opacity 0.3s $easeOutCubic var(--delay, 0s), transform 0.3s $easeOutCubic var(--delay, 0s);
				}

				.select { --delay: 0.1s }
				.mission-panel { --delay: 0.1s }
				.mission-panel ~ .select { --delay: 0.2s }
			}
		}
	}
	
	.mission-panel {
		border: 2px solid white;
		background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
		backdrop-filter: blur(vw(25));
		border-radius: vw(20);
		z-index: -1;
		margin-top: vw(40);
		padding: vw(20);

		.title {
			font-weight: 700;
			font-size: vw(18);
			line-height: 1.1;
			margin-bottom: vw(12);
		}

		.subtitle {
			font-weight: 700;
			font-size: vw(12);
			line-height: 1.1;
			display: none;
		}

		.description {
			font-weight: 500;
			font-size: vw(12);
			line-height: 1.1;
			margin-bottom: vw(10);
		}

		.clues {
			text-align: center;
			margin-bottom: vw(16);
			font-size: vw(12);
			font-weight: 600;
			line-height: 1;

			.list {
				display: flex;
				gap: vw(11);
				justify-content: center;
			}

			.clue {
				width: vw(36);
				height: vw(36);

				img {
					width: 100%;
					opacity: 0.3;
				}

				&.product {
					width: auto;
					height: vw(50);

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						opacity: 0.25;
					}
				}

				&.completed {
					img {
						opacity: 1;
					}
				}
			}
		}
	}

	.matching-panel {
		border: 2px solid white;
		background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
		backdrop-filter: blur(vw(25));
		border-radius: vw(20);
		padding: vw(20);

		.title {
			font-weight: 700;
			font-size: vw(25);
			line-height: 1.1;
			margin-bottom: vw(12);
		}

		.subtitle {
			font-weight: 400;
			font-size: vw(14);
			line-height: 1.1;
		}

		.description {
			font-weight: 400;
			font-size: vw(16);
			line-height: 1.1;
			margin-bottom: vw(18);
		}

		.button {
			margin-bottom: 0;

			&:not(:last-child) {
				margin-bottom: vw(10);
			}
		}

		.sub-panel {
			background: rgba(217, 217, 217, 0.50);
			border-radius: vw(20);
			padding: vw(15);

			.title {
				font-size: vw(22);
			}

			.description {
				font-size: vw(14);
			}

			& + .sub-panel {
				margin-top: vw(18);
			}
		}
	}

	&.appear,
	&.enter {
		opacity: 0;

		&-active {
			opacity: 1;
			transition: opacity 0.3s $easeOutCubic;
		}
	}

	&.exit {
		opacity: 1;

		&-active {
			opacity: 0;
			transition: opacity 0.3s $easeInCubic;
		}
	}
}

.barcode-scanner-error-message {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 999999;
	overflow: hidden;

	background: rgba(black, 0.25);
	backdrop-filter: blur(vw(10));

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;

		width: vw(266);
		padding: vw(20);
		color: var(--color-text-primary);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid white;
			background: linear-gradient(127.29deg, rgba(255, 255, 255, 0.8) 11.46%, rgba(255, 255, 255, 0.7) 26.96%, rgba(255, 255, 255, 0.6) 48.21%, rgba(255, 255, 255, 0.5) 68.88%);
			backdrop-filter: blur(vw(25));
			border-radius: vw(20);
			z-index: -1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 200vw;
			height: 200vh;
			background: rgba(0, 0, 0, 0.5);
			backdrop-filter: blur(vw(25));
			z-index: -2;
		}

		.close-icon {
			position: absolute;
			top: vw(14);
			right: vw(14);
			width: vw(24);
			height: auto;
			cursor: pointer;
		}

		.title {
			font-weight: 500;
			font-size: vw(22);
			line-height: 1;

			&:not(:last-child) {
				margin-bottom: vw(12);
			}
		}

		.subtitle {
			font-weight: 500;
			font-size: vw(14);
			line-height: 1.1;

			&:not(:last-child) {
				margin-bottom: vw(12);
			}
		}

		.description {
			font-weight: 400;
			font-size: vw(14);
			line-height: 1.1;
			margin-bottom: vw(12);
		}
	}

	.link {
		margin-top: vw(10);
		color: black;
		text-align: center;
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: vw(12);
		font-style: normal;
		font-weight: 700;
		line-height: vw(16);
		text-transform: uppercase;
		text-decoration-line: underline;
	}

	&.appear,
	&.enter {
		opacity: 0;
		transform: translate(0, #{vw(40)});

		.content > * {
			opacity: 0;
			transform: translate(0, #{vw(20)});
		}

		&-active {
			& {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			& .content > * {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.content :nth-child(1) { --delay: 0.2s; }
			.content :nth-child(2) { --delay: 0.3s; }
			.content :nth-child(3) { --delay: 0.4s; }
			.content :nth-child(4) { --delay: 0.5s; }
			.content :nth-child(5) { --delay: 0.5s; }
		}
	}

	&.exit {
		opacity: 1;

		&-active {
			opacity: 0;
			transition: opacity 0.3s $easeInCubic;
		}
	}
}