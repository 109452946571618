.intro-modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 20;
	background: rgba(#B2FFDF, 0.25);
	backdrop-filter: blur(20px);
	padding: vw(20) vw(20) vw(20) vw(20);
	display: flex;
	flex-direction: column;
	z-index: 999;

	overflow-x: hidden;
	overflow-y: auto;

	.intro-modal-content {
		width: vw(352);
		background: linear-gradient(124.01deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.3) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.7) 72.44%);
		border-radius: vw(21);
		padding: vw(20);
		margin-top: auto;
		margin-bottom: auto;
		border: vw(1) solid white;

		.button {
			margin-bottom: vw(30);
		}
	
		.title {
			font-weight: 800;
			font-size: vw(50);
			line-height: vw(50);
			text-transform: uppercase;
			margin-bottom: vw(30);
	
			strong {
				font-weight: 800;
				font-size: vw(93.5942);
				line-height: 0.7;
				text-transform: uppercase;
				color: var(--color-accent-primary);
			}
		}
	
		.groups {
			display: flex;
			flex-direction: column;
			gap: vw(10);
		}
	
		.group {
			display: flex;
			align-items: center;
			gap: vw(10);
	
			p {
				font-weight: 400;
				font-size: vw(15);
				line-height: vw(21);
				color: black;
			}
	
			.icon {
				width: vw(90);
				height: auto;
			}
	
			.content {
				width: vw(223);
			}
		}
	}

	.button {
		width: max-content;
		margin: 0 auto;
	}
}