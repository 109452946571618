%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;
	font-size: vw(18);
	line-height: vw(20);
	text-transform: uppercase;
	color: var(--color-button-text);
	background-color: transparent;
	border: none;
	padding: vw(16) vw(24);
	border-radius: 9999px;
	font-family: var(--font-family-button);
	
	text-align: center;
	font-weight: 500;
	cursor: pointer;
	background-color: var(--color-button-background);

	transition: color 0.6s ease, opacity 0.6s ease;
}

[class^=button] {
	@extend %base_button;

	&.full-width {
		width: 100%;
	}

	&.thin {
		padding: vw(10) vw(24);
		font-size: vw(14);
	}

	&.treasure-hunt {
		background-color: var(--color-treasure-hunt);
	}

	&.product-scan {
		background-color: var(--color-product-scan);
	}

	&.secondary {
		background-color: white;
		color: black;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}