.missions-page {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100 - var(--menu-height));
	padding-bottom: vw(20);
	padding-top: calc(var(--header-clear) + #{vw(60)});

	justify-content: flex-start;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;

	.tabs {
		position: fixed;
		top: var(--header-clear);
		left: vw(25);
		width: calc(100vw - #{vw(50)});
		display: flex;
		gap: vw(7);
		z-index: 2;
		text-align: center;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 1 100%;

			font-size: vw(14);
			font-weight: 700;
			line-height: 1;
			padding: vw(10) vw(10);
			border-radius: vw(36);
			border: vw(2) solid var(--icon-color, #FFF);
			background: var(--WHITE, linear-gradient(124deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.30) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.70) 72.44%));

			&:not(.active) {
				opacity: 0.5;
			}
		}
	}

	.missions-list {
		width: calc(100% - vw(50));
		height: 100%;

		.swiper {
			overflow: visible;
			height: 100%;
		}
	}

	.mission {
		position: relative;
		height: 100%;

		.mission-bubble {
			position: absolute;
			top: vw(-162);
			left: 50%;
			transform: translate(-50%, 0);
			z-index: -1;

			.bubble {
				--size: #{vw(287)};
			}

			.animated-bottle-cap {
				position: absolute;
				top: vw(42);
				left: vw(25);
				--size: #{vw(165)};
				z-index: 1;
			}

			.city {
				position: absolute;
				top: vw(39);
				left: vw(90);
				width: vw(174);
				height: auto;
			}

			.bottle {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			padding: vw(20);
			border: vw(1) solid white;
			border-radius: vw(22);
			background: rgba(white, 0.3);
			backdrop-filter: blur(vw(10));
			height: 100%;

			.scrollable {
				display: flex;
				flex-direction: column;

				overflow-x: hidden;
				overflow-y: auto;
				flex: 1 1 auto;
				padding: 0 vw(20);
				margin-left: calc(-1 * #{vw(20)});
				margin-right: calc(-1 * #{vw(20)});

				&::-webkit-scrollbar-thumb {
					background-color: var(--accent);
				}
	
				&::-webkit-scrollbar-button:start:increment {
					width: vw(25);
					display: block;
				}
		
				&::-webkit-scrollbar-button:end:increment {
					width: vw(25);
					display: block;
				}
			}

			--accent: var(--color-accent-primary);

			&.treasure-hunt {
				--accent: var(--color-treasure-hunt);
			}

			&.product-scan {
				--accent: var(--color-product-scan);
			}
		}

		.rewards {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: vw(10);

			.plus,
			.xp {
				text-transform: uppercase;
				font-size: vw(16);
				font-weight: 900;
				color: black;
			}

			.gift-card-icon {
				position: relative;
				margin-left: vw(10);

				svg {
					width: vw(50);
					height: auto;
					color: black;
				}

				.currency {
					position: absolute;
					right: vw(6);
					bottom: vw(9);
					font-size: vw(12);
					font-weight: 900;
					color: white;
				}
			}

			.badge {
				width: vw(80);
				height: auto;
				margin: vw(-5) vw(-10);
			}
		}
		
		.bottom {
			margin-top: auto;
			flex: 0 0 auto;
		}

		.type {
			font-weight: 600;
			font-size: vw(14);
			line-height: vw(17);
			/* identical to box height */
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
			align-self: flex-start;
			border-radius: vw(20);
			height: vw(20);
			padding: vw(1) vw(10) vw(0);
			color: white;
			margin-bottom: vw(5);
			flex: 0 0 auto;
			background-color: var(--accent);

			&.locked {
				background-color: #6C6C6C;
			}
		}

		.title {
			font-weight: 700;
			font-size: vw(25);
			line-height: vw(30);
			padding-bottom: vw(10);
			margin-bottom: vw(10);
			border-bottom: vw(2) dashed var(--accent);
		}

		.subtitle {
			font-weight: 700;
			font-size: vw(14);
			line-height: vw(17);
			margin-bottom: 0.25em;
		}

		p, pre {
			font-family: var(--font-family-text);
			font-size: vw(14);
			line-height: vw(17);
		}

		.button.locked {
			opacity: 0.3;
		}

		.additional-infos {
			font-size: vw(14);
			line-height: vw(17);
			margin-bottom: 0.25em;
		}

		.clues {
			text-align: center;
			font-size: vw(12);
			font-weight: 600;
			line-height: 1;

			.list {
				display: flex;
				gap: vw(14);
				justify-content: center;
			}

			.clue {
				width: vw(65);
				height: vw(65);

				img {
					width: 100%;
					opacity: 0.3;
				}

				&.product {
					width: auto;
					height: vw(45);
					margin-top: vw(16);

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
						opacity: 0.25;
					}
				}

				&.completed {
					img {
						opacity: 1;
					}
				}
			}

			.clues-count {
				margin-top: vw(5);
			}
		}

		.button {
			margin-top: vw(16);
		}

		.type,
		.title,
		p,
		.reward-element,
		.additional-infos,
		.clue,
		.clues-count,
		.button {
			opacity: 0 !important;
			transform: translate(0, #{vw(20)});
			transition: opacity 0.3s $easeInCubic var(--delay, 0s), transform 0.3s $easeInCubic var(--delay, 0s);
		}

		&.swiper-slide-active {
			.type,
			.title,
			p,
			.reward-element,
			.additional-infos,
			.clue,
			.clues-count,
			.button {
				opacity: 1 !important;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.button.disabled {
				opacity: 0.5 !important;
			}

			.type { --delay: 0.05s }
			.title { --delay: 0.10s }
			p { --delay: 0.15s }
			.reward-element { --delay: 0.20s }
			.additional-infos { --delay: 0.25s }
			.clue { --delay: 0.30s }
			.clues-count { --delay: 0.35s }
			.button { --delay: 0.4s }
		}
	}

	.tabs.appear,
	.tabs.enter {
		opacity: 0;
		transform: translate(0, #{vw(20)});

		&-active {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
		}
	}

	.missions-list.appear,
	.missions-list.enter {
		opacity: 0;
		transform: translate(0, #{vw(20)});

		&-active {
			opacity: 1;
			transform: translate(0, 0);
			transition: opacity 0.6s $easeOutCubic var(--delay, 0.2s), transform 0.6s $easeOutCubic var(--delay, 0.2s);
		}
	}
}