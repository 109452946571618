.rewards-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);

	background: rgba(#66a289, 0.25);
	backdrop-filter: blur(vw(20));
	z-index: 100;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: vw(24);
	padding: vw(60) 0 vw(60);

	overflow-x: hidden;
	overflow-y: auto;

	.panel {
		position: relative;
		padding: vw(20);
		width: vw(320);
		border-radius: vw(24);
		background: linear-gradient(124.01deg, rgba(255, 255, 255, 0.81) 12.05%, rgba(255, 255, 255, 0.3) 28.35%, rgba(255, 255, 255, 0.47) 50.7%, rgba(255, 255, 255, 0.7) 72.44%);
		backdrop-filter: blur(5px);
		z-index: 1;

		&.rewards-slider {
			> .title {
				font-weight: 400;
				font-size: vw(14);
				line-height: vw(17);
				text-align: center;
			}

			> .subtitle {
				font-weight: 700;
				font-size: vw(25);
				line-height: vw(30);
				text-align: center;
				color:black;
			}

			.animated-bottle-cap {
				margin: 0 auto vw(30);
				--size: #{vw(160)};
			}

			img {
				width: vw(160);
				height: vw(160);
				object-fit: contain;
				margin: 0 auto;
				display: block;
				filter: drop-shadow(0 #{vw(9.4)} #{vw(9.4)} rgba(0, 0, 0, 0.25));

				&.badge {
					width: vw(240);
					height: vw(240);
					margin: vw(-40) auto;
				}
			}

			.button {
				margin-top: vw(10);
			}

			.reward-subtitle {
				text-align: center;
				font-weight: 700;
				font-size: vw(21);
				line-height: vw(25);
				margin-bottom: vw(13);
				color: black;
			}

			.reward-description {
				text-align: center;
				font-weight: 400;
				font-size: vw(14);
				line-height: vw(20);
			}

			.swiper {
				padding-bottom: vw(21);
			}

			.btn-ctn {
				display: flex;
				width: 100%;
				justify-content: center;

				.button {
					margin-top: 0;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				position: absolute;
				top: vw(70);
				width: vw(27);
				height: vw(27);
				border-radius: 100%;
				background: var(--color-treasure-hunt);
				z-index: 2;

				&.swiper-button-disabled {
					opacity: 0.3;
					background: white;
					pointer-events: none;

					&:after {
						border-top-color: #484848;
						border-right-color: #484848;
					}
				}

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: vw(8);
					height: vw(8);
					border-top: vw(2) solid white;
					border-right: vw(2) solid white;
				}
			}

			.swiper-button-prev {
				left: 0;

				&:after {
					transform: translate(-25%, -50%) rotate(-135deg);
				}
			}

			.swiper-button-next {
				right: 0;

				&:after {
					transform: translate(-50%, -50%) rotate(45deg);
				}
			}

			.swiper-pagination {
				position: absolute;
				bottom: vw(0);
				width: 100%;
				display: flex;
				justify-content: center;
				gap: vw(4);

				.swiper-pagination-bullet {
					display: block;
					width: vw(12);
					height: vw(12);
					border-radius: 100%;
					background: white;
					opacity: 0.3;
					
					&-active {
						opacity: 1;
					}
				}
			}
		}

		.rewards {
			margin-top: vw(10);

			.title {
				font-weight: 700;
				font-size: vw(17);
				line-height: vw(12);
				margin-bottom: vw(12);
				color: black;
			}

			.items {
				display: flex;
				flex-direction: column;
				font-size: vw(14);
				gap: vw(6);
				line-height: vw(20);

				&:not(:last-child) {
					margin-bottom: vw(12);
				}

				.item {
					display: flex;
					justify-content: space-between;

					.name {
						opacity: 0.6;
					}
	
					.stat {
						text-align: right;
						color: black;
					}
				}

				.item.total {
					padding-top: vw(6);
					border-top: vw(1) solid rgba(0, 0, 0, 0.20);
					color: black;
					font-weight: 900;

					.name {
						opacity: 1;
					}
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(rgb(183, 183, 183), 0.74);
				backdrop-filter: blur(vw(50));
				border-radius: vw(24);
				z-index: -1;
			}

			&:after {
				content: '';
				position: absolute;
				top: vw(-2);
				left: vw(-2);
				right: vw(-2);
				bottom: vw(-2);
				border-radius: vw(26);
				background: var(--rainbow-gradient-faded);
				z-index: -2;
			}
		}
	}

	.next-mission {
		padding: vw(12) vw(20);
		background: var(--color-treasure-hunt);
		color: white;
		border-radius: vw(10);
		margin-top: vw(12);

		font-size: vw(14);
		font-weight: 600;
		line-height: 1.14;
	}

	.btn-ctn {
		width: vw(320);
		margin-top: auto;
	}

	.bubble {
		position: relative;
	}

	.objective-rewards {
		position: relative;
		width: vw(395);
		height: vw(395);
		box-sizing: content-box;
		padding-top: vw(80);
		padding-bottom: vw(70);

		.animated-bottle-cap {
			position: absolute;
			top: 0;
			left: vw(10);
			--size: #{vw(220)};
			z-index: 1;
		}

		.bubble-ctn {
			width: vw(395);
			height: vw(395);
			display: grid;
			align-items: center;
			justify-content: center;

			.bubble,
			.content {
				grid-row: 1;
				grid-column: 1;
			}

			.bubble {
				width: 100%;
				height: 100%;
			}

			.content {
				z-index: 1;
				text-align: center;
				padding: vw(50);
				color: black;

				.es & {
					padding: vw(60);
				}

				.title {
					text-transform: uppercase;
					font-weight: 900;
					font-size: vw(32);
					line-height: 1.1;
					margin-bottom: vw(5);

					.es & {
						font-size: vw(24);
					}
				}

				.status {
					font-weight: 500;
					font-size: vw(12);
					line-height: 1.1;
					margin-bottom: vw(11);
				}

				.description {
					font-weight: 500;
					font-size: vw(14);
					line-height: 1.4;
					margin-bottom: vw(20);
				}
			}
		}

		.xp-bubble {
			position: absolute;
			bottom: 0;
			right: 0;

			width: vw(218);
			height: vw(218);
			display: grid;
			align-items: center;
			justify-content: center;

			.bubble,
			.content {
				grid-row: 1;
				grid-column: 1;
			}

			.bubble {
				width: 100%;
				height: 100%;

				&:after {
					width: 80%;
					height: 85%;
				}
			}

			.content {
				z-index: 1;
				text-align: center;
				padding: vw(10) vw(50) 0;
				color: black;
				font-size: vw(32);
				font-weight: 900;
			}
		}
	}

	&.objective {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;

		.button {
			width: calc(100% - #{vw(100)});
		}

		&.appear,
		&.enter {
			opacity: 0;

			.animated-bottle-cap,
			.bubble-ctn,
			.xp-bubble {
				opacity: 0;
				transform: translate(0, #{vw(40)});
			}
			
			&-active {
				opacity: 1;
				transition: opacity 0.3s $easeOutCubic;

				.animated-bottle-cap,
				.bubble-ctn,
				.xp-bubble {
					opacity: 1;
					transform: translate(0, 0);
					transition: transform 0.6s $easeOutCubic var(--delay, 0.2s), opacity 0.6s $easeOutCubic var(--delay, 0.2s);
				}

				.animated-bottle-cap { --delay: 0.1s }
				.bubble-ctn { --delay: 0.2s }
				.xp-bubble { --delay: 0.3s }
			}
		}
	}

	&:not(.objective) {
		&.enter,
		&.appear {
			opacity: 0;

			.panel,
			.btn-ctn {
				opacity: 0;
				transform: translate(0, #{vw(40)});
			}

			.rewards-slider > .title,
			.rewards-slider > .subtitle,
			.swiper,
			.rewards .title,
			.rewards .items,
			.rewards .total {
				opacity: 0;
				transform: translate(0, #{vw(20)});
			}

			&-active {
				opacity: 1;
				transition: opacity 0.3s $easeOutCubic;

				.panel,
				.btn-ctn,
				.rewards-slider > .title,
				.rewards-slider > .subtitle,
				.swiper,
				.rewards .title,
				.rewards .items,
				.rewards .total {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
				}

				.panel { --delay: 0.2s }
				.btn-ctn { --delay: 0.3s }
				.rewards-slider > .title { --delay: 0.4s }
				.rewards-slider > .subtitle { --delay: 0.5s }
				.swiper { --delay: 0.6s }
				.rewards .title { --delay: 0.7s }
				.rewards .items { --delay: 0.8s }
				.rewards .total { --delay: 0.9s }
			}
		}
	}
}