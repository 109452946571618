.research-consent {
	height: calc(100 * var(--vh, 1vh));
	display: flex;
	align-items: center;
	justify-content: center;

	.panel {
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: vw(16);
		width: vw(300);
		padding: vw(16);

		border-radius: vw(16);
		border: vw(3) solid var(--glass-transparent);
		background: var(--glass-gradient);
		backdrop-filter: blur(vw(10));

		.title {
			font-family: var(--font-family-title);
			font-size: vw(30);
			font-weight: 800;
			line-height: 1.1;
		}

		.text {
			font-size: vw(14);
			font-weight: 400;
			line-height: vw(20);
		}
	}
}