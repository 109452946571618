.auth-hub {
	height: calc(100 * var(--vh, 1vh));
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.logo {
		width: auto;
		height: vw(111);
		margin-bottom: vw(40);

		img {
			width: auto;
			height: 100%;
			max-width: none;
		}
	}

	.panel {
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: vw(16);
		width: vw(300);
		padding: vw(16);
		font-size: vw(14);

		border-radius: vw(16);
		border: vw(3) solid var(--glass-transparent);
		background: var(--glass-gradient);
		backdrop-filter: blur(vw(10));

		.title {
			font-family: var(--font-family-title);
			font-size: vw(30);
			font-weight: 800;
			line-height: 1.2;
		}

		.button {
			width: max-content;
			margin: 0 auto;
		}

		.text {
			font-size: vw(16);
			font-weight: 400;
			line-height: 1.2;
		}

		.t-and-c {
			font-size: vw(13);
			font-weight: 400;
			line-height: 1.2;

			a {
				font-weight: 800;
			}
		}
	}

	.login {
		font-weight: bold;
		text-decoration: underline;
		cursor: pointer;
	}

	&.appear,
	&.enter {
		.panel,
		.logo {
			opacity: 0;
			transform: translate(0, #{vw(40)});
		}

		.panel > * {
			opacity: 0;
			transform: translate(0, #{vw(20)});
		}

		.panel {
			overflow: hidden;
		}

		&-active {
			.panel,
			.panel > *,
			.logo {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.panel {
				--delay: 0.2s;
			}

			.panel :nth-child(1) { --delay: 0.2s; }
			.panel :nth-child(2) { --delay: 0.3s; }
			.panel :nth-child(3) { --delay: 0.4s; }
			.panel :nth-child(4) { --delay: 0.5s; }
		}
	}
}